import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addEnquiryFollowup } from "../../../actions";
import { Input } from "../../../components/UI/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
/**
 * @author
 * @function Followup
 **/

export const AddFollowup = (props) => {
  const [method, setMethod] = useState();
  const [notes, setNotes] = useState();
  const [followupDate, setFollowupDate] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.followup.error);

  const onModalSaveClicked = (props) => {
    const data = {
      method: method,
      followupDate: followupDate,
      notes: notes,
      id: id,
    };

    dispatch(addEnquiryFollowup(data));
    if (!error) {
      setMethod();
      setNotes();
      setFollowupDate();
      props.onHide();
    }
  };

  return (
    <>
      <Form>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Set up a Followup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <Form.Label className="form-label" htmlFor="notification-type">
                Method
              </Form.Label>
              <select
                id="notification-type"
                className="form-select"
                required
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="">Choose</option>
                <option value="email">Email</option>
                <option value="sms">SMS</option>
                <option value="whatsapp">Whatsapp</option>
                <option value="call">Phone Call</option>
              </select>
            </div>
            <div className="mb-3">
              <Form.Label className="form-label" htmlFor="due_date">
                Followup Date
              </Form.Label>
              <DatePicker
                selected={followupDate}
                onChange={(date) => setFollowupDate(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="d MMMM, yyyy h:mm aa"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <Form.Label className="form-label" htmlFor="notes">
                Note
              </Form.Label>
              <Input
                id="notes"
                type="text"
                placeholder="Notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={() => onModalSaveClicked(props)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};
