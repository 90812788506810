import React from "react";
import { TrendingDown, TrendingUp } from "react-feather";

/**
 * @author
 * @function Summary
 **/

export const Summary = (props) => {
  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <table className="table">
            <tbody>
              <tr>
                <td>Total Income</td>
                <td className="text-right">
                  {props.credit > 0 ? props.credit.toFixed(2) : "0.00"}
                </td>
              </tr>
              <tr>
                <td>Total Expense</td>
                <td className="text-right">
                  {props.debit > 0 ? props.debit.toFixed(2) : "0.00"}
                </td>
              </tr>
              <tr className="table-active">
                <td>
                  <b>
                    {props.balance > 0 ? "Recived in Advance" : "Total Dues"}
                  </b>
                </td>
                <td className="align-ight">
                  {"" !== props.balance && undefined !== props.balance
                    ? props.balance.toFixed(2)
                    : "N/A"}

                  {props.balance > 0 ? (
                    <TrendingUp
                      className="feather feather-home align-text-bottom"
                      color="#539165"
                      style={{ marginLeft: "10px" }}
                    />
                  ) : props.balance < 0 ? (
                    <TrendingDown
                      className="feather feather-home align-text-bottom"
                      color="#FF0000"
                      style={{ marginLeft: "10px" }}
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
