import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { getAllActivityLog } from "./activity.action";
import { enquiryConstant } from "./constants";
import { getAllEnquiryFollowup } from "./followup.action";

export const getAllEnquiry = (payload) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.GET_ALL_ENQUIRY_REQUEST });
    const res = await axios.get("/enquiry", { params: payload });

    if (res.status === 200) {
      const { enquiryList } = res.data;
      dispatch({
        type: enquiryConstant.GET_ALL_ENQUIRY_SUCCESS,
        payload: {
          enquiries: enquiryList,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_ALL_ENQUIRY_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const getSingleEnquiry = (id) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.GET_SINGLE_ENQUIRY_REQUEST });
    const res = await axios.get("/enquiry/" + id);

    if (res.status === 200) {
      const enquiryItem = res.data.data;

      dispatch(getAllActivityLog(id));
      dispatch(getAllEnquiryFollowup(id));
      dispatch({
        type: enquiryConstant.GET_SINGLE_ENQUIRY_SUCCESS,
        payload: {
          enquiry: enquiryItem,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_SINGLE_ENQUIRY_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const addEnquiry = (form) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.ADD_NEW_ENQUIRY_REQUEST,
    });
    const res = await axios.post("/enquiry", form);
    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_SUCCESS,
        payload: res.data.message,
      });
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_FAILURE,
        payload: res.data.message,
      });
    }
  };
};

export const deleteEnquiry = (data) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.DELETE_ENQUIRY_REQUEST,
    });
    const res = await axios.delete("/enquiry/" + data.id);
    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.DELETE_ENQUIRY_SUCCESS,
        payload: res.data.message,
      });
      dispatch(getAllEnquiry());
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.DELETE_ENQUIRY_FAILUE,
        payload: res.data.message,
      });
    }
  };
};

export const enquiryStatusUpdate = (data) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.UPDATE_ENQUIRY_STATUS_REQUEST,
    });

    const res = await axios.post("/enquiry/status/" + data.id, data);

    if (!res || 200 !== res.status) {
      dispatch({
        type: enquiryConstant.UPDATE_ENQUIRY_STATUS_FAILURE,
        payload: res.data.message,
      });
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.UPDATE_ENQUIRY_STATUS_SUCCESS,
        payload: res.data.message,
      });
      dispatch(getAllActivityLog(data.id));
      dispatch(getSingleEnquiry(data.id));
      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};
