import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CustomerInfoCard } from "../CustomerInfoCard";

/**
 * @author
 * @function AppModal
 **/

export const AppModal = (props) => {
  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
    props.toggleCall();
  };

  useEffect(() => {
    if (props.show) setShow(props.show);
  }, [props.show]);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {"customer" === props.infoType ? (
            <CustomerInfoCard data={props.card}></CustomerInfoCard>
          ) : (
            <></>
          )}
        </Modal.Body>
        {props.footer ? (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
