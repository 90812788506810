import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addEnquiryNote } from "../../../actions";
import { Input } from "../../../components/UI/Input";

/**
 * @author
 * @function Followup
 **/

const AddNote = (props) => {
  const [notes, setNotes] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const error = useSelector((state) => state.acitivity.error);

  const onModalSaveClicked = (props) => {
    const data = {
      id: id,
      description: notes,
    };

    dispatch(addEnquiryNote(data));
    if (!error) {
      props.onHide();
      setNotes("");
    }
  };

  return (
    <>
      <Form>
        <Modal
          {...props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Add a Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-floating mb-3">
              <Input
                type="text"
                placeholder="Add Comment"
                className="form-control"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                id="notes"
              />
              <Form.Label className="form-label" htmlFor="notes">
                Comments/Notes
              </Form.Label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onHide}>
              Close
            </Button>
            <Button variant="primary" onClick={() => onModalSaveClicked(props)}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default AddNote;
