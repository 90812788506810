import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Rings } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteUser, getAllUsers } from "../../actions";
import { Layout } from "../../components/Layout";

/**
 * @author
 * @function Customers
 **/

export const Users = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(getAllUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomerDataTable = (users) => {
    const columns = [
      {
        name: "Name",
        selector: (row) => row.fullname,
        sortable: true,
      },
      {
        name: "Type",
        selector: (row) => row.user_type,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        name: "Mobile",
        selector: (row) => row.mobile,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => row.user_status,
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          if ("Super Admin" != record.user_type) {
            return (
              <>
                <a
                  href={"/users/edit/" + record.id}
                  className="btn btn-primary btn-sm"
                  role="button"
                >
                  Edit
                </a>{" "}
                &nbsp;
                <Button
                  type="submit"
                  variant="danger"
                  size="sm"
                  onClick={() => onDeleteClick(record)}
                >
                  Delete
                </Button>
              </>
            );
          } else {
            return <></>;
          }
        },
      },
    ];

    let usersList = [];

    if ("" !== users && 0 < users.length) {
      for (let user of users) {
        usersList.push({
          id: user.id,
          fullname: user.name,
          user_type: "su" == user.user_type ? "Super Admin" : "Executive",
          email: user.email,
          mobile: "" == user.mobile ? "-" : user.mobile,
          user_status: "1" == user.status ? "Active" : "Blocked",
        });
      }
    }
    return <DataTable columns={columns} data={usersList} pagination />;
  };

  const onDeleteClick = (data) => {
    const id = data.id;
    dispatch(deleteUser(id));
  };

  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Users</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Link
              to={"/users/add"}
              className="btn btn-sm btn-outline-secondary"
            >
              Add New
            </Link>
          </div>
        </div>
      </div>

      {users.loading && !users.error ? (
        <Rings />
      ) : (
        renderCustomerDataTable(users.users)
      )}
    </Layout>
  );
};
