import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addEnquiry, getAllCustomers } from "../../actions";
import { Layout } from "../../components/Layout";
import { LInput } from "../../components/UI/LInput";

/**
 * @author
 * @function AddEnquiry
 **/

const AddEnquiry = (props) => {
  const [enquiryFrom, setEnquiryFrom] = useState("");
  const [agent, setAgent] = useState("");
  const [addAsB2B, setAddAsB2B] = useState(false);
  const [fullname, setFullname] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [duedate, setDueDate] = useState("");

  const dispatch = useDispatch();
  const enquiry = useSelector((state) => state.enquiry);
  const { customers } = useSelector((state) => state.customers);
  const navigate = useNavigate();

  const onEnquiryFromChange = (value) => {
    setEnquiryFrom(value);

    if ("b2b" === value) {
      dispatch(getAllCustomers());
    }
  };

  const populateAgentsDropdown = (data) => {
    return data.map((data) => {
      return (
        <option key={data.id} value={data.id}>
          {"" !== data.fullname
            ? `${data.fullname}, ${data.company_name}`
            : `${data.company_name}`}
        </option>
      );
    });
  };

  const saveEnquiry = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("agent", agent);
    form.append("fullname", fullname);
    form.append("company_name", companyName);
    form.append("address", address);
    form.append("email", email);
    form.append("mobile", mobile);
    form.append("is_b2b", addAsB2B);
    form.append("title", title);
    form.append("details", details);
    form.append("duedate", duedate);

    dispatch(addEnquiry(form));

    if (!enquiry.error) {
      navigate("/enquiry");
    }
  };

  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Enquiries</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary float-end"
            >
              Add New
            </button>
            <button type="button" className="btn btn-sm btn-outline-secondary">
              View Trash
            </button>
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-md-12 col-lg-12">
          <h5 className="mb-3">Customer Information</h5>
          <Form className="needs-validation" onSubmit={saveEnquiry}>
            <div className="row g-3">
              <div className="col-sm-6">
                <label className="form-label" htmlFor="Full name">
                  Enquiry From {enquiryFrom}
                </label>
                <select
                  className="form-select"
                  value={enquiryFrom}
                  onChange={(e) => onEnquiryFromChange(e.target.value)}
                  required
                >
                  <option value={""}>Choose</option>
                  <option value={"b2b"}>B2B</option>
                  <option value={"regular"}>Regular</option>
                </select>
              </div>
              {"b2b" === enquiryFrom ? (
                <div className="col-sm-6">
                  <label className="form-label" htmlFor="Full name">
                    Agent
                  </label>
                  <select
                    className="form-select"
                    value={agent}
                    onChange={(e) => setAgent(e.target.value)}
                    required
                  >
                    <option value={""}>Choose</option>
                    {populateAgentsDropdown(customers)}
                  </select>
                </div>
              ) : "regular" === enquiryFrom ? (
                <div className="row g-3">
                  <div className="col-sm-6">
                    <LInput
                      type="text"
                      forLabel="fullname"
                      labelName="Full name"
                      labelClass="form-label"
                      className="form-control"
                      placeholder="Full name"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <LInput
                      type="text"
                      forLabel="companyName"
                      labelName="Company Name"
                      labelClass="form-label"
                      className="form-control"
                      placeholder="Company name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-12">
                    <LInput
                      type="text"
                      forLabel="address"
                      labelName="Address"
                      labelClass="form-label"
                      className="form-control"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <LInput
                      type="text"
                      forLabel="mobile"
                      labelName="Mobile No"
                      labelClass="form-label"
                      className="form-control"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-6">
                    <LInput
                      type="text"
                      forLabel="email"
                      labelName="Email"
                      labelClass="form-label"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        value={addAsB2B}
                        type="checkbox"
                        id="active"
                        defaultChecked={addAsB2B}
                        onChange={() => setAddAsB2B(!addAsB2B)}
                      />
                      <Form.Label className="form-check-label" htmlFor="active">
                        Save this Customer as B2B Agent
                      </Form.Label>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <hr className="my-4" />
            <h5 className="mb-3">Enquiry Details</h5>
            <div className="row gy-3">
              <div className="col-md-12">
                <LInput
                  type="text"
                  forLabel="title"
                  labelName="Enqiury Title"
                  labelClass="form-label"
                  className="form-control"
                  placeholder="Summary Line"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <LInput
                  type="textarea"
                  forLabel="title"
                  labelName="Enqiury Details"
                  labelClass="form-label"
                  className="form-control"
                  placeholder=""
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
            </div>
            <hr className="my-4" />
            <Button
              variant="primary"
              className="btn btn-md float-right"
              type="submit"
            >
              Save & Continue
            </Button>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default AddEnquiry;
