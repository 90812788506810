import React from "react";
import {
  MessageSquare as SMS,
  Mail,
  PhoneOutgoing as Phone,
  Send as Whatsapp,
} from "react-feather";

/**
 * @author
 * @function Followup
 **/

export const Followup = (props) => {
  let disabledClass = "1" === props.completed ? "bg-light" : "";

  let icon = null;

  if ("whatsapp" === props.type) {
    icon = (
      <Whatsapp
        className="feather feather-home align-text-bottom"
        size={10}
      ></Whatsapp>
    );
  } else if ("sms" === props.type) {
    icon = (
      <SMS className="feather feather-home align-text-bottom" size={10}></SMS>
    );
  } else if ("call" === props.type) {
    icon = (
      <Phone
        className="feather feather-home align-text-bottom"
        size={10}
      ></Phone>
    );
  } else {
    icon = (
      <Mail className="feather feather-home align-text-bottom" size={10}></Mail>
    );
  }

  return (
    <>
      <label className={`list-group-item d-flex gap-3 ${disabledClass}`}>
        <input
          className="form-check-input flex-shrink-0"
          type="checkbox"
          value={props.value}
          onChange={props.onChange}
          style={{ fontSize: "1.375em" }}
          checked={"1" === props.completed ? true : false}
        />

        <span className="pt-1 form-checked-content">
          <strong>{props.title}</strong>
          <small className="d-block text-muted">
            (#FW{props.id}) {icon} &nbsp; <br />
            {props.due_date}
          </small>
        </span>
      </label>
    </>
  );
};
