import React from "react";
import { ListGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Mail, Smartphone } from "react-feather";

/**
 * @author
 * @function CustomerInfoCard
 **/

export const CustomerInfoCard = (props) => {
  return (
    <Card style={{ width: "18rem", border: "none" }}>
      <Card.Body>
        <Card.Title></Card.Title>
        <Card.Text></Card.Text>
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <h4>{props.data.fullname ?? props.data.company}</h4>
          </ListGroup.Item>
          <ListGroup.Item>
            <Smartphone size={16}></Smartphone> Mobile No : {props.data.mobile}
          </ListGroup.Item>
          <ListGroup.Item>
            <Mail size={16}></Mail> Email : {props.data.email}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
