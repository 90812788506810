import React from "react";
import { Layout } from "../../components/Layout";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Input } from "../../components/UI/Input";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * @author
 * @function Signup
 **/

const Signup = (props) => {
  const auth = useSelector((state) => state.auth);

  if (auth.authenticate) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <Row style={{ marginTop: "50px" }}>
        <Col md={{ span: 6, offset: 3 }}>
          <Form>
            <Row>
              <Col md={6}>
                <Input
                  type="text"
                  placeholder="First Name"
                  label="First Name"
                  value=""
                  onChange={() => {}}
                />
              </Col>
              <Col md={6}>
                <Input
                  type="text"
                  placeholder="Last Name"
                  label="Last Name"
                  value=""
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <Input
              type="email"
              placeholder="Enter Email"
              label="Email"
              value=""
              onChange={() => {}}
            />

            <Input
              type="password"
              placeholder="Enter Password"
              label="Password"
              value=""
              onChange={() => {}}
            />
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
};

export default Signup;
