import { Button, Form } from "react-bootstrap";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signout } from "../../actions";
import logo from "../../logo.png";

/**
 * @author
 * @function Header
 **/

export const Header = (props) => {
  const dispatch = useDispatch();

  const logout = (e) => {
    e.preventDefault();
    dispatch(signout());
  };

  return (
    <>
      <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <Link to="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6">
          <img src={logo} alt="TripifyMe" width={86} />
        </Link>
        <Button
          className="navbar-toggler position-absolute d-md-none collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </Button>
        <Form.Control
          type="text"
          placeholder="Search"
          value={props.value}
          onChange={props.onChange}
          className="form-control form-control-dark w-100 rounded-0 border-0"
        />
        <div className="navbar-nav">
          <div className="nav-item text-nowrap">
            <Link to="/" className="nav-link px-3" onClick={logout}>
              Signout
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};
