import { authConstants } from "../actions/constants";

const initialState = {
  access_token: null,
  user: {
    name: "",
    email: "",
  },
  authenticate: false,
  authenticating: false,
  loading: false,
  error: false,
  message: "",
};

const authReducers = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = initialState;
      break;

    case authConstants.LOGIN_FAILURE:
      state = {
        ...state,
        message: action.payload.error,
        error: true,
      };
      break;

    case authConstants.LOGIN_SUCCESS:
      state = {
        ...state,
        user: action.payload.user,
        access_token: action.payload.access_token,
        authenticate: true,
        authenticating: false,
      };
      break;
    case authConstants.LOGOUT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case authConstants.LOGOUT_SUCCESS:
      state = {
        ...initialState,
      };
      break;

    case authConstants.LOGOUT_FAILURE:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;

    default:
      return state;
  }
  return state;
};

export default authReducers;
