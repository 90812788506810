import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Rings } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { addUser, getUserDetails, updateUser } from "../../actions";
import { Layout } from "../../components/Layout";
import { Input } from "../../components/UI/Input";

/**
 * @author
 * @function Customers
 **/

export const AddUser = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const [mobile, setMobile] = useState("");
  const [userType, setUserType] = useState("");
  const [expertise, setExpertise] = useState("");
  const [active, setActive] = useState("");

  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("fullname", fullname);
    form.append("user_type", userType);
    form.append("mobile", mobile);
    form.append("email", email);
    form.append("password", password);
    form.append("expertise", expertise);
    form.append("status", active);

    dispatch(addUser(form));

    if (!users.error) {
      navigate("/users");
    }
  };

  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Add User</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2"></div>
        </div>
      </div>

      {users.loading && !users.error ? (
        <Rings />
      ) : (
        <Form className="row g-3" onSubmit={onFormSubmit}>
          <div className="col-md-6">
            <Form.Label className="form-label" htmlFor="email">
              Email
            </Form.Label>
            <Input
              id="email"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <Form.Label className="form-label" htmlFor="password">
              Password
            </Form.Label>
            <Input
              id="email"
              type="password"
              placeholder="Password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-12">
            <Form.Label className="form-label" htmlFor="name">
              Full name
            </Form.Label>
            <Input
              id="name"
              type="text"
              placeholder="Full name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-12">
            <Form.Label className="form-label" htmlFor="name">
              Expertise
            </Form.Label>
            <Input
              id="expertise"
              type="text"
              placeholder="Expertise ( Use comma seperation for multiple)"
              value={expertise}
              onChange={(e) => setExpertise(e.target.value)}
              className="form-control"
            />
          </div>

          <div className="col-md-6">
            <Form.Label className="form-label" htmlFor="mobile">
              Mobile
            </Form.Label>
            <Input
              id="mobile"
              type="text"
              placeholder="Mobile"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-6">
            <Form.Label className="form-label" htmlFor="user_type">
              User Type
            </Form.Label>
            <select
              id="user_type-type"
              className="form-select"
              required
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="">Choose</option>
              <option value="a">Admin</option>
              <option value="e">Sales Manager</option>
            </select>
          </div>
          <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input"
                value={active}
                type="checkbox"
                id="active"
                defaultChecked={active}
                onChange={() => setActive(!active)}
              />
              <Form.Label className="form-check-label" htmlFor="active">
                Active
              </Form.Label>
            </div>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-primary">
              Sign in
            </button>
          </div>
        </Form>
      )}
    </Layout>
  );
};
