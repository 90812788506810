import React from "react";
import { NavLink } from "react-router-dom";
import { Header } from "../Header";
import {
  Home as HomeIcon,
  Users,
  Smile,
  HelpCircle,
  Calendar,
} from "react-feather";

/**
 * @author
 * @function Layout
 **/

export const Layout = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <>
      {props.header ? <Header /> : <></>}
      {props.sidebar ? (
        <>
          <div className="container-fluid">
            <div className="row"></div>
            <nav
              id="sidebarMenu"
              className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
            >
              <div className="position-sticky pt-3">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink to={"/"} className="nav-link active">
                      <HomeIcon
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></HomeIcon>
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/enquiry"} className="nav-link">
                      <HelpCircle
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></HelpCircle>
                      Enquiry
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={"/customers"} className="nav-link active">
                      <Smile
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Smile>
                      Customers
                    </NavLink>
                  </li>
                  <li
                    className={`nav-item ${
                      "su" !== user.data.user_type ? "d-none" : ""
                    }`}
                  >
                    <NavLink to={"/users"} className="nav-link active">
                      <Users
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Users>
                      Users
                    </NavLink>
                  </li>
                  <li
                    className={`nav-item ${
                      "su" !== user.data.user_type ? "d-none" : ""
                    }`}
                  >
                    <NavLink to={"/expenses"} className="nav-link active">
                      <Users
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Users>
                      General Expenses
                    </NavLink>
                  </li>
                </ul>
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted text-uppercase">
                  <span>Income and Expense reports</span>
                </h6>
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink
                      to={"/expenses/reports/this-month"}
                      className="nav-link active"
                    >
                      <Calendar
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Calendar>
                      This Month
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/expenses/reports/last-month"}
                      className="nav-link active"
                    >
                      <Calendar
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Calendar>
                      Last Month
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/expenses/reports/last-3-months"}
                      className="nav-link active"
                    >
                      <Calendar
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Calendar>
                      Last 3 Months
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/expenses/reports/last-6-months"}
                      className="nav-link active"
                    >
                      <Calendar
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Calendar>
                      Last 6 Months
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/expenses/reports/advanced"}
                      className="nav-link active"
                    >
                      <Calendar
                        className="feather feather-home align-text-bottom"
                        size={16}
                      ></Calendar>
                      Advanced
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
              {props.children}
              <footer className="my-5 pt-2 text-muted text-center text-small">
                <p className="mb-1">
                  © 2017–2022 Tripify Ltd. All rights reserved.
                </p>
              </footer>
            </main>
          </div>
        </>
      ) : (
        props.children
      )}
    </>
  );
};
