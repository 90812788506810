import React from "react";
import { Form } from "react-bootstrap";

/**
 * @author
 * @function Input
 **/

export const Input = (props) => {
  return (
    <>
      <Form.Control
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        className={props.className}
        id={props.id || `__id_${props.className}`}
        required={props.required}
      />
      <Form.Label>{props.label}</Form.Label>
    </>
  );
};
