import React from "react";
import { Form } from "react-bootstrap";

/**
 * @author
 * @function LInput
 **/

export const LInput = (props) => {
  return (
    <>
      {props.forLabel ? (
        <Form.Label htmlFor={props.labelName} className={props.labelClass}>
          {props.labelName}
        </Form.Label>
      ) : (
        <></>
      )}
      {props.type === "textarea" ? (
        <Form.Control
          as="textarea"
          rows={7}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          className={props.className}
          required={props.required}
        />
      ) : (
        <Form.Control
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          className={props.className}
          required={props.required}
        />
      )}

      {props.invalid_feedback ? (
        <div className="invalid-feedback">Valid first name is required.</div>
      ) : (
        <></>
      )}
    </>
  );
};
