import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { userConstants } from "./constants";

export const signup = (user) => {
  return async (dispatch) => {
    dispatch({ type: userConstants.USER_REGISTER_REQUEST });

    const res = await axios.post("/signup", {
      ...user,
    });

    if (res.status === 200) {
      const { message } = res.data.message;
      dispatch({
        type: userConstants.USER_REGISTER_SUCCESS,
        payload: {
          message,
        },
      });
    } else {
      if (res.status !== 200) {
        dispatch({
          type: userConstants.USER_REGISTER_FAILURE,
          payload: { error: res.data.error },
        });
      }
    }
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.GET_ALL_USERS_REQUEST,
    });

    const res = await axios.get(`/users`);

    if (res.status === 200) {
      const { data } = res.data;

      dispatch({
        type: userConstants.GET_ALL_USERS_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: userConstants.GET_ALL_USERS_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const getUserDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.GET_USER_REQUEST,
    });

    const res = await axios.get(`/users/${id}`);

    if (res.status === 200) {
      const { data } = res.data;
      dispatch({
        type: userConstants.GET_USER_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: userConstants.GET_USER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const updateUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.EDIT_USER_REQUEST,
    });

    const res = await axios.post(`/users/${data.get("id")}`, data);

    if (res.data === 200) {
      const { data, message } = res.data;

      dispatch({
        type: userConstants.EDIT_USER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: userConstants.EDIT_USER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const addUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.ADD_USER_REQUEST,
    });

    const res = await axios.post(`/users`, data);

    if (res.data === 200) {
      const { data, message } = res.data;

      dispatch({
        type: userConstants.ADD_USER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: userConstants.ADD_USER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const getAllSalesManagers = () => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.GET_ALL_SALES_MANAGERS_REQUEST,
    });

    const res = await axios.get(`/users`, { params: { user_type: "e" } });

    if (res.status === 200) {
      const { data } = res.data;

      dispatch({
        type: userConstants.GET_ALL_SALES_MANAGER_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: userConstants.GET_ALL_SALES_MANAGER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({
      type: userConstants.DELETE_USER_REQUEST,
    });

    const res = await axios.delete(`/users/${id}`);

    if (res.status === 200) {
      const { data, message } = res.data;

      dispatch({
        type: userConstants.DELETE_USER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      dispatch(getAllUsers());

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: userConstants.DELETE_USER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};
