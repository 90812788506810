import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { deleteEnquiry, getAllCustomers, getAllEnquiry } from "../../actions";
import Toast from "../../components/UI/Toast";
import { Rings } from "react-loader-spinner";
import { AppModal } from "../../components/UI/AppModal";
import { EnquiryMenu } from "../../components/Header/Enquiry/Menu";
import EnquiryDataTable from "../../components/UI/EnquiryDataTable";
import { EnquiryFilter } from "../../components/UI/EnquiryFilter";

const Enquiry = (props) => {
  const dispatch = useDispatch();
  const enquiry = useSelector((state) => state.enquiry);
  const { user } = useSelector((state) => state.auth);
  const [customerInfo, setCustomerInfo] = useState("");
  const [showCustomerInfo, setShowCustomerInfo] = useState(false);
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [agency, setAgency] = useState("");

  useEffect(() => {
    const params = {
      enquiry_status: enquiryStatus,
      agent: agency,
      show_trash: "trash" === props.type ? true : false,
    };
    dispatch(getAllEnquiry(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agency, enquiryStatus]);

  const onDeleteClick = (data) => {
    dispatch(deleteEnquiry(data));
    if (!enquiry.error) {
      <Toast
        message="Enquiry has been deleted successfully"
        type="Success"
      ></Toast>;
      dispatch(getAllCustomers());
    }
  };

  const onInfoClick = (data) => {
    setShowCustomerInfo((current) => !current);
    setCustomerInfo(data.customer);
  };

  const toggleCustomerInfo = () => {
    //setShowCustomerInfo(!showCustomerInfo);
    setShowCustomerInfo((current) => !current);
  };

  const onSearchFilterSubmit = (params) => {
    setAgency(params.agency);
    setEnquiryStatus(params.enquiry_status);
  };

  return (
    <Layout sidebar header>
      <EnquiryMenu title="Enquiries" />
      <EnquiryFilter onFilterSubmit={onSearchFilterSubmit} />
      <hr className="my-4"></hr>
      {enquiry.loading && !enquiry.error ? (
        <Rings />
      ) : (
        <EnquiryDataTable
          user={user}
          onDeleteClick={onDeleteClick}
          onInfoClick={onInfoClick}
          enquiries={enquiry}
        />
      )}

      {"" !== customerInfo ? (
        <AppModal
          infoType="customer"
          footer
          title={"Customer Details"}
          card={customerInfo}
          show={showCustomerInfo}
          toggleCall={toggleCustomerInfo}
        ></AppModal>
      ) : (
        <></>
      )}
    </Layout>
  );
};

export default Enquiry;
