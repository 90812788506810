import { combineReducers } from "redux";
import authReducers from "./auth.reducers";
import followupReducer from "./followup.reducer";
import enquiryReducers from "./enquiry.reducers";
import activityReducer from "./activity.reducer";
import transactionReducers from "./transaction.reducers";
import customersReducers from "./customers.reducers";
import userReducer from "./users.reducers";

const rootReducer = combineReducers({
  auth: authReducers,
  enquiry: enquiryReducers,
  followup: followupReducer,
  acitivity: activityReducer,
  transactions: transactionReducers,
  customers: customersReducers,
  users: userReducer,
});

export default rootReducer;
