import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Rings } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCustomer, getAllCustomers } from "../../actions";
import { Layout } from "../../components/Layout";

/**
 * @author
 * @function Customers
 **/

export const Customers = (props) => {
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers);

  useEffect(() => {
    dispatch(getAllCustomers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCustomerDataTable = (customers) => {
    const columns = [
      {
        name: "Name",
        selector: (row) => row.fullname,
        sortable: true,
      },
      {
        name: "Company",
        selector: (row) => row.company_name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.mobile,
        sortable: true,
      },
      {
        name: "phone",
        selector: (row) => row.email,
        sortable: true,
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <>
              <a
                href={"/customers/edit/" + record.id}
                className="btn btn-primary btn-sm"
                role="button"
              >
                Edit
              </a>{" "}
              &nbsp;
              <Button
                className={`${"su" !== user.data.user_type ? "d-none" : ""}`}
                type="submit"
                variant="danger"
                size="sm"
                onClick={() => onDeleteClick(record)}
              >
                Delete
              </Button>
            </>
          );
        },
      },
    ];

    let customersList = [];

    if ("" !== customers && 0 < customers.length) {
      for (let customer of customers) {
        customersList.push({
          id: customer.id,
          fullname: customer.fullname,
          company_name: customer.company_name,
          email: customer.email,
          mobile: customer.mobile,
        });
      }
    }
    return <DataTable columns={columns} data={customersList} pagination />;
  };

  const onDeleteClick = (data) => {
    const id = data.id;
    dispatch(deleteCustomer(id));
  };
  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Customers</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Link
              to={"/customers/add"}
              className="btn btn-sm btn-outline-secondary"
            >
              Add New
            </Link>
          </div>
        </div>
      </div>

      {customers.loading && !customers.error ? (
        <Rings />
      ) : (
        renderCustomerDataTable(customers.customers)
      )}
    </Layout>
  );
};
