import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { customerConstants } from "./constants";

export const getAllCustomers = () => {
  return async (dispatch) => {
    dispatch({
      type: customerConstants.GET_ALL_CUSTOMERS_REQUEST,
    });

    const res = await axios.get(`/customers`);

    if (res.status === 200) {
      const { data } = res.data;

      dispatch({
        type: customerConstants.GET_ALL_CUSTOMERS_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: customerConstants.GET_ALL_CUSTOMERS_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const getCustomerDetails = (id) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstants.GET_SINGLE_CUSTOMER_REQUEST,
    });

    const res = await axios.get(`/customers/${id}`);

    if (res.status === 200) {
      const { data } = res.data;
      dispatch({
        type: customerConstants.GET_SINGLE_CUSTOMER_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: customerConstants.GET_SINGLE_CUSTOMER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const addCustomer = (data) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstants.ADD_CUSTOMER_REQUEST,
    });

    const res = await axios.post(`/customers`, data);

    if (res.data === 200) {
      const { data, message } = res.data;

      dispatch({
        type: customerConstants.ADD_CUSTOMER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: customerConstants.ADD_CUSTOMER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const updateCustomer = (data) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstants.EDIT_CUSTOMER_REQUEST,
    });

    const res = await axios.post(`/customers/${data.get("id")}`, data);

    if (res.data === 200) {
      const { data, message } = res.data;

      dispatch({
        type: customerConstants.EDIT_CUSTOMER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: customerConstants.EDIT_CUSTOMER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};

export const deleteCustomer = (id) => {
  return async (dispatch) => {
    dispatch({
      type: customerConstants.DELETE_CUSTOMER_REQUEST,
    });

    const res = await axios.delete(`/customers/${id}`);

    if (res.status === 200) {
      const { data, message } = res.data;

      dispatch({
        type: customerConstants.DELETE_CUSTOMER_SUCCESS,
        payload: {
          data: data,
          message: message,
        },
      });

      dispatch(getAllCustomers());

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: customerConstants.DELETE_CUSTOMER_FAILURE,
        payload: {
          message: res.data.message,
        },
      });
    }
  };
};
