export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const userConstants = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",
  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",
  GET_ALL_SALES_MANAGERS_REQUEST: "GET_ALL_SALES_MANAGERS_REQUEST",
  GET_ALL_SALES_MANAGER_SUCCESS: "GET_ALL_SALES_MANAGER_SUCCESS",
  GET_ALL_SALES_MANAGER_FAILURE: "GET_ALL_SALES_MANAGER_FAILURE",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  ADD_USER_REQUEST: "ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "ADD_USER_SUCCESS",
  EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_SUCCESS",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_SUCCESS",
  BLOCK_USER_REQUEST: "BLOCK_USER_REQUEST",
  BLOCK_USER_SUCCESS: "BLOCK_USER_SUCCESS",
  BLOCK_USER_FAILURE: "BLOCK_USER_SUCCESS",
};

export const customerConstants = {
  GET_ALL_CUSTOMERS_REQUEST: "GET_ALL_CUSTOMERS_REQUEST",
  GET_ALL_CUSTOMERS_SUCCESS: "GET_ALL_CUSTOMERS_SUCCESS",
  GET_ALL_CUSTOMERS_FAILURE: "GET_ALL_CUSTOMERS_FAILURE",
  GET_SINGLE_CUSTOMER_REQUEST: "GET_SINGLE_CUSTOMER_REQUEST",
  GET_SINGLE_CUSTOMER_SUCCESS: "GET_SINGLE_CUSTOMER_SUCCESS",
  GET_SINGLE_CUSTOMER_FAILURE: "GET_SINGLE_CUSTOMER_FAILURE",
  ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
  ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
  ADD_CUSTOMER_FAILURE: "ADD_CUSTOMER_FAILURE",
  EDIT_CUSTOMER_REQUEST: "EDIT_CUSTOMER_REQUEST",
  EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
  EDIT_CUSTOMER_FAILURE: "EDIT_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_REQUEST: "DELETE_CUSTOMER_REQUEST",
  DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_FAILURE: "DELETE_CUSTOMER_SUCCESS",
  BLOCK_CUSTOMER_REQUEST: "BLOCK_CUSTOMER_REQUEST",
  BLOCK_CUSTOMER_SUCCESS: "BLOCK_CUSTOMER_SUCCESS",
  BLOCK_CUSTOMER_FAILURE: "BLOCK_CUSTOMER_SUCCESS",
};

export const enquiryConstant = {
  GET_SINGLE_ENQUIRY_REQUEST: "GET_SINGLE_ENQUIRY_REQUEST",
  GET_SINGLE_ENQUIRY_SUCCESS: "GET_SINGLE_ENQUIRY_SUCCESS",
  GET_SINGLE_ENQUIRY_FAILURE: "GET_SINGLE_ENQUIRY_FAILURE",
  GET_ALL_ENQUIRY_REQUEST: "GET_ALL_ENQUIRY_REQUEST",
  GET_ALL_ENQUIRY_SUCCESS: "GET_ALL_ENQUIRY_SUCCESS",
  GET_ALL_ENQUIRY_FAILURE: "GET_ALL_ENQUIRY_FAILURE",
  ADD_NEW_ENQUIRY_REQUEST: "ADD_NEW_ENQUIRY_REQUEST",
  ADD_NEW_ENQUIRY_SUCCESS: "ADD_NEW_ENQUIRY_SUCCESS",
  ADD_NEW_ENQUIRY_FAILURE: "ADD_NEW_ENQUIRY_FAILURE",
  DELETE_ENQUIRY_REQUEST: "DELETE_ENQUIRY_REQUEST",
  DELETE_ENQUIRY_SUCCESS: "DELETE_ENQUIRY_SUCCESS",
  DELETE_ENQUIRY_FAILUE: "DELETE_ENQUIRY_FAILUE",
  UPDATE_ENQUIRY_STATUS_REQUEST: "UPDATE_ENQUIRY_STATUS_REQUEST",
  UPDATE_ENQUIRY_STATUS_SUCCESS: "UPDATE_ENQUIRY_STATUS_SUCCESS",
  UPDATE_ENQUIRY_STATUS_FAILURE: "UPDATE_ENQUIRY_STATUS_FAILURE",
  GET_ALL_ACTIVITY_LOG_REQUEST: "GET_ALL_ACTIVITY_LOG_REQUEST",
  GET_ALL_ACTIVITY_LOG_SUCCESS: "GET_ALL_ACTIVITY_LOG_SUCCESS",
  GET_ALL_ACTIVITY_LOG_FAILURE: "GET_ALL_ACTIVITY_LOG_FAILURE",
  ADD_NEW_ENQUIRY_NOTE_REQUEST: "ADD_NEW_ENQUIRY_NOTE_REQUEST",
  ADD_NEW_ENQUIRY_NOTE_SUCCESS: "ADD_NEW_ENQUIRY_NOTE_SUCCESS",
  ADD_NEW_ENQUIRY_NOTE_FAILURE: "ADD_NEW_ENQUIRY_NOTE_FAILURE",
  ADD_NEW_ENQUIRY_FOLLOWUP_REQUEST: "ADD_NEW_ENQUIRY_FOLLOWUP_REQUEST",
  ADD_NEW_ENQUIRY_FOLLOWUP_SUCCESS: "ADD_NEW_ENQUIRY_FOLLOWUP_SUCCESS",
  ADD_NEW_ENQUIRY_FOLLOWUP_FAILURE: "ADD_NEW_ENQUIRY_FOLLOWUP_FAILURE",
  GET_ALL_ENQUIRY_FOLLOWUP_REQUEST: "GET_ALL_ENQUIRY_FOLLOWUP_REQUEST",
  GET_ALL_ENQUIRY_FOLLOWUP_SUCCESS: "GET_ALL_ENQUIRY_FOLLOWUP_SUCCESS",
  GET_ALL_ENQUIRY_FOLLOWUP_FAILURE: "GET_ALL_ENQUIRY_FOLLOWUP_FAILURE",
  SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_REQUEST:
    "SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_REQUEST",
  SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_SUCCESS:
    "SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_SUCCESS",
  SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_FAILURE:
    "SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_FAILURE",
  ADD_TRANSACTION_NOTE_REQUEST: "ADD_TRANSACTION_NOTE_REQUEST",
  ADD_TRANSACTION_NOTE_SUCCESS: "ADD_TRANSACTION_NOTE_SUCCESS",
  ADD_TRANSACTION_NOTE_FAILURE: "ADD_TRANSACTION_NOTE_SUCCESS",
  GET_ALL_TRANSACTION_NOTE_REQUEST: "GET_ALL_TRANSACTION_NOTE_REQUEST",
  GET_ALL_TTRANSACTION_NOTE_SUCCESS: "GET_ALL_TRANSACTION_NOTE_SUCCESS",
  GET_ALL_TTRANSACTION_NOTE_FAILURE: "GET_ALL_TRANSACTION_NOTE_SUCCESS",
  GET_TRANSACTION_SUMMARY_REQUEST: "GET_TRANSACTION_SUMMARY_REQUEST",
  GET_TRANSACTION_SUMMARY_SUCCESS: "GET_TRANSACTION_SUMMARY_SUCCESS",
  GET_TRANSACTION_SUMMARY_FAILURE: "GET_TRANSACTION_SUMMARY_FAILURE",
  GET_TRANSACTION_DETAILS_REQUEST: "GET_TRANSACTION_DETAILS_REQUEST",
  GET_TRANSACTION_DETAILS_SUCCESS: "GET_TRANSACTION_DETAILS_SUCCESS",
  GET_TRANSACTION_DETAILS_FAILURE: "GET_TRANSACTION_DETAILS_FAILURE",
  VOID_TRANSACTION_REQUEST: "VOID_TRANSACTION_REQUEST",
  VOID_TRANSACTION_SUCCESS: "VOID_TRANSACTION_SUCCESS",
  VOID_TRANSACTION_FAILURE: "VOID_TRANSACTION_FAILURE",
  UPLOAD_TRANSACTION_PROOF_REQUEST: "UPLOAD_TRANSACTION_PROOF_REQUEST",
  UPLOAD_TRANSACTION_PROOF_SUCCESS: "UPLOAD_TRANSACTION_PROOF_SUCCESS",
  UPLOAD_TRANSACTION_PROOF_FAILURE: "UPLOAD_TRANSACTION_PROOF_FAILURE",
  DOWNLOAD_TRANSACTION_PROOF_REQUEST: "DOWNLOAD_TRANSACTION_PROOF_REQUEST",
  DOWNLOAD_TRANSACTION_PROOF_SUCCESS: "DOWNLOAD_TRANSACTION_PROOF_SUCCESS",
  DOWNLOAD_TRANSACTION_PROOF_FAILURE: "DOWNLOAD_TRANSACTION_PROOF_FAILURE",
};
