import React from "react";
import { EnquiryMenu } from "../../components/Header/Enquiry/Menu";
import { Layout } from "../../components/Layout";
import { TransactionNotes } from "../Enquiry/TransactionNotes";

/**
 * @author
 * @function Orders
 **/

const Expenses = (props) => {
  return (
    <Layout sidebar header>
      <EnquiryMenu title="General Expenses" hideToolbar={true}></EnquiryMenu>
      <div className="row g-5">
        <div className="col-md-12 col-lg-12">
          <TransactionNotes
            hideSummary={true}
            type={"general"}
          ></TransactionNotes>
        </div>
      </div>
    </Layout>
  );
};

export default Expenses;
