import { userConstants } from "../actions/constants";

const initState = {
  loading: true,
  error: null,
  users: [],
  message: null,
  user: {},
  sales_managers: {},
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case userConstants.GET_ALL_USERS_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case userConstants.GET_ALL_USERS_SUCCESS:
      var { data, message } = action.payload;
      state = {
        ...state,
        loading: false,
        error: false,
        users: data,
        message: message,
      };
      break;
    case userConstants.GET_ALL_USERS_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.message,
      };
      break;
    case userConstants.GET_ALL_SALES_MANAGERS_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case userConstants.GET_ALL_SALES_MANAGER_SUCCESS:
      var { data, message } = action.payload;
      state = {
        ...state,
        loading: false,
        error: false,
        sales_managers: data,
        message: message,
      };
      break;
    case userConstants.GET_ALL_SALES_MANAGER_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.message,
      };
      break;
    case userConstants.GET_USER_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case userConstants.GET_USER_SUCCESS:
      const userData = action.payload.data;
      state = {
        ...state,
        loading: false,
        error: false,
        user: userData,
      };

      break;
    case userConstants.GET_USER_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.message,
      };
      break;
    case userConstants.EDIT_USER_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case userConstants.EDIT_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case userConstants.EDIT_USER_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.message,
      };
      break;
    case userConstants.ADD_USER_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case userConstants.ADD_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case userConstants.ADD_USER_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.message,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default userReducer;
