import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { isUserLoggedIn } from "./actions";
import PrivateRoute from "./components/HOC/PrivateRoute";
import Category from "./containers/Category";
import Enquiry from "./containers/Enquiry";
import AddEnquiry from "./containers/Enquiry/add";
import { EnquiryDetails } from "./containers/Enquiry/details";
import Signin from "./containers/Signin";
import Signup from "./containers/Signup";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Customers } from "./containers/Customers";
import { Users } from "./containers/Users";
import { EditUser } from "./containers/Users/edit";
import { AddUser } from "./containers/Users/add";
import { AddCustomer } from "./containers/Customers/add";
import { EditCustomer } from "./containers/Customers/edit";
import "./App.css";
import Expenses from "./containers/Expenses";
import { ExpenseReport } from "./containers/Expenses/Report";
import { AdvancedReport } from "./containers/Expenses/Report/Advanced";
import TimeLine from "./containers/Enquiry/TimeLine";

function App() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Enquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/category"
          element={
            <PrivateRoute>
              <Category />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry"
          exact
          element={
            <PrivateRoute>
              <Enquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/add"
          exact
          element={
            <PrivateRoute>
              <AddEnquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/trash"
          exact
          element={
            <PrivateRoute>
              <Enquiry type="trash" />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/ongoing"
          exact
          element={
            <PrivateRoute>
              <TimeLine type="ongoing" key={Date.now()} />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/upcoming"
          exact
          element={
            <PrivateRoute>
              <TimeLine type="upcoming" key={Date.now()} />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/completed"
          exact
          element={
            <PrivateRoute>
              <TimeLine type="completed" />
            </PrivateRoute>
          }
        />
        <Route
          path="/enquiry/edit/:id"
          exact
          element={
            <PrivateRoute>
              <EnquiryDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <PrivateRoute>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers/add"
          element={
            <PrivateRoute>
              <AddCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/customers/edit/:id"
          element={
            <PrivateRoute>
              <EditCustomer />
            </PrivateRoute>
          }
        />
        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/edit/:id"
          exact
          element={
            <PrivateRoute>
              <EditUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/add"
          element={
            <PrivateRoute>
              <AddUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/expenses"
          element={
            <PrivateRoute>
              <Expenses />
            </PrivateRoute>
          }
        />
        <Route
          path="/expenses/reports/:id"
          exact
          element={
            <PrivateRoute>
              <ExpenseReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/expenses/reports/advanced"
          exact
          element={
            <PrivateRoute>
              <AdvancedReport />
            </PrivateRoute>
          }
        />
        <Route path="/signin" caseSensitive={false} element={<Signin />} />
        <Route path="/signup" caseSensitive={false} element={<Signup />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
