import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { getAllActivityLog } from "./activity.action";
import { enquiryConstant } from "./constants";

export const addTransactionNote = (data) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.ADD_TRANSACTION_NOTE_REQUEST });

    const res = await axios.post(
      "/enquiry/transactions/" + data.get("enquiry_id"),
      data
    );

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.ADD_TRANSACTION_NOTE_SUCCESS,
        payload: {
          success: true,
          error: false,
          message: res.data.message,
        },
      });
      dispatch(getAllActivityLog(data.get("enquiry_id")));
      dispatch(getAllTransactions(data.get("enquiry_id")));

      toast.success(res.data.message || "Action completed successfully", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_NOTE_FAILURE,
        error: res.data.message,
        success: "",
      });
    }
  };
};

export const getAllTransactions = (enquiry_id, duration = "", params = "") => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.GET_ALL_TRANSACTION_NOTE_REQUEST });

    const res = await axios.get("/enquiry/transactions/" + enquiry_id, {
      params: { duration: duration, search: params },
    });

    if (res.status === 200) {
      const { data } = res.data;

      dispatch({
        type: enquiryConstant.GET_ALL_TTRANSACTION_NOTE_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_ALL_TTRANSACTION_NOTE_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const getTransactionNoteDetails = (note_id) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.GET_TRANSACTION_DETAILS_REQUEST });
    const res = await axios.get(`/enquiry/transactions/details/${note_id}`);

    if (res.status === 200) {
      const { data } = res.data;

      dispatch({
        type: enquiryConstant.GET_TRANSACTION_DETAILS_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_TRANSACTION_DETAILS_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const getTransactionSummary = (enquiry_id) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.GET_TRANSACTION_SUMMARY_REQUEST,
    });

    const res = await axios.get("/enquiry/transactions/summary/" + enquiry_id);

    if (200 === res.status) {
      const { data } = res.data;

      dispatch({
        type: enquiryConstant.GET_TRANSACTION_SUMMARY_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_TRANSACTION_SUMMARY_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const voidTransactionNote = (id, enquiry_id) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.VOID_TRANSACTION_REQUEST });

    const res = await axios.post(`/enquiry/transactions/void/${id}`);

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.VOID_TRANSACTION_SUCCESS,
        payload: {
          success: true,
          error: false,
          message: res.data.message,
        },
      });
      dispatch(getAllActivityLog(enquiry_id));
      dispatch(getAllTransactions(enquiry_id));

      toast.success(res.data.message || "Action completed successfully", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.VOID_TRANSACTION_FAILURE,
        error: res.data.message,
        success: "",
      });
    }
  };
};

export const uploadTransactionProof = (data) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.UPLOAD_TRANSACTION_PROOF_REQUEST });

    const res = await axios.post(
      `/enquiry/transactions/upload/${data.get("id")}`,
      data
    );

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.UPLOAD_TRANSACTION_PROOF_SUCCESS,
        payload: {
          success: true,
          error: false,
          message: res.data.message,
        },
      });
      dispatch(getAllActivityLog(data.get("enquiry_id")));
      dispatch(getAllTransactions(data.get("enquiry_id")));

      toast.success(res.data.message || "Action completed successfully", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.UPLOAD_TRANSACTION_PROOF_FAILURE,
        error: res.data.message,
        success: "",
      });

      toast.error(res.data.message || "Something went wrong", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};

export const downloadTransactionProof = (transaction_id) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.DOWNLOAD_TRANSACTION_PROOF_REQUEST });

    const res = await axios.post(
      `/enquiry/transactions/download/${transaction_id}`
    );

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.DOWNLOAD_TRANSACTION_PROOF_SUCCESS,
        payload: {
          success: true,
          error: false,
          data: res.data.data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.DOWNLOAD_TRANSACTION_PROOF_FAILURE,
        error: res.data.message,
        success: "",
      });

      toast.error(res.data.message || "Something went wrong", {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};
