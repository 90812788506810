import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Edit, Paperclip, RotateCcw, RotateCw, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllTransactions } from "../../../actions";
import { Layout } from "../../../components/Layout";
import { downloadTransactionProof } from "../../../actions";
import { AdvancedDataTable } from "../../../components/UI/EnquiryDataTable/advanced";
/**
 * @author
 * @function TransactionReport
 **/

export const ExpenseReport = (props) => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions);
  const [transactionId, setTransactionId] = useState("");

  let { id } = useParams("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    //var "id" is the url param to identify the filter duration
    dispatch(getAllTransactions(0, id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (null != transactions.download_data) {
      let binary = `${transactions.download_data.file_content}`;

      let a = document.createElement("a");
      a.href = binary;
      a.download = transactions.download_data.file_name;
      a.click();
    }
  }, [transactions.download_data]);

  const renderTransactionTableView = (data, id) => {
    const columns = [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "75px",
        cell: (record) => {
          return `#${record.id}`;
        },
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        width: "200px",
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        wrap: true,
      },
      {
        name: "Debit",
        selector: (row) => row.debit,
        sortable: true,
        width: "100px",
      },
      {
        name: "Credit",
        selector: (row) => row.credit,
        sortable: true,
        width: "100px",
      },
      {
        key: "action",
        text: "Proof",
        className: "action",
        width: "130px",
        align: "left",
        sortable: false,
        cell: (record) => {
          return "" !== record.attachment ? (
            <ButtonGroup className="mb-2" size="sm">
              <Button
                title="Attachments"
                variant="secondary"
                onClick={(e) =>
                  onAttachButtonClick(e, record.id, record.attachment)
                }
              >
                <Paperclip size={10}>{record.attachment}</Paperclip>
              </Button>
            </ButtonGroup>
          ) : (
            ""
          );
        },
      },
    ];

    let tableData = [];

    for (let trans of data) {
      let transactionDate = format(
        new Date(trans.transaction_date),
        "dd-LLL-yyyy hh:ii a"
      );

      tableData.push({
        id: trans.id,
        description: trans.description,
        date: transactionDate,
        user: trans.user.name,
        debit: trans.type === "debit" ? trans.amount : "",
        credit: trans.type === "credit" ? trans.amount : "",
        attachment: trans.attachment,
        status: trans.status,
      });
    }

    return (
      <AdvancedDataTable
        title="Advanced Transaction Report"
        columns={columns}
        data={tableData}
      />
    );
  };

  const onAttachButtonClick = (e, id, attachmentName) => {
    e.preventDefault();
    if ("" !== attachmentName) {
      dispatch(downloadTransactionProof(id));
    }
  };

  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Income Expense Report</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2"></div>
        </div>
      </div>
      {!transactions.loading && transactions.transactions.length > 0
        ? renderTransactionTableView(transactions.transactions, id)
        : ""}
    </Layout>
  );
};
