import { enquiryConstant } from "../actions/constants";

const initState = {
  loading: true,
  error: null,
  transactions: [],
  message: null,
  summary: null,
  transaction: null,
  download_data: null,
};

const transactionReducer = (state = initState, action) => {
  switch (action.type) {
    case enquiryConstant.GET_ALL_TRANSACTIONS_NOTE_REQUEST:
      state = initState;
      break;
    case enquiryConstant.GET_ALL_TTRANSACTION_NOTE_SUCCESS:
      const { transactions, summary } = action.payload.data;
      state = {
        ...state,
        loading: false,
        error: false,
        transactions: transactions,
        summary: summary,
        message: action.payload.success,
      };
      break;

    case enquiryConstant.GET_ALL_TRANSACTION_NOTE_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_SUMMARY_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_SUMMARY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        summary: action.payload.data,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_SUMMARY_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.ADD_TRANSACTION_NOTE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.ADD_TRANSACTION_NOTE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.ADD_TRANSACTION_NOTE_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_DETAILS_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        transaction: action.payload.data,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.GET_TRANSACTION_DETAILS_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.VOID_TRANSACTION_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.VOID_TRANSACTION_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.VOID_TRANSACTION_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.UPLOAD_TRANSACTION_PROOF_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.UPLOAD_TRANSACTION_PROOF_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.UPLOAD_TRANSACTION_PROOF_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.DOWNLOAD_TRANSACTION_PROOF_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.DOWNLOAD_TRANSACTION_PROOF_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
        download_data: action.payload.data,
      };
      break;
    case enquiryConstant.UPLOAD_TRANSACTION_PROOF_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
        download_data: null,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default transactionReducer;
