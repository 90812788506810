import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllEnquiry, getAllTransactions } from "../../../../actions";
import { Layout } from "../../../../components/Layout";
import { AdvancedDataTable } from "../../../../components/UI/EnquiryDataTable/advanced";
import { Input } from "../../../../components/UI/Input";
import { downloadTransactionProof } from "../../../../actions";
import { Paperclip } from "react-feather";
/**
 * @author
 * @function AdvancedReport
 **/

export const AdvancedReport = (props) => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions);
  let { id } = useParams("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [enquiryItem, setEnquiryItem] = useState([]);
  const enquiryList = useSelector((state) => state.enquiry.enquiries);

  useEffect(() => {
    //var "id" is the url param to identify the filter duration
    dispatch(getAllTransactions(0, id));
    dispatch(getAllEnquiry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (null != transactions.download_data) {
      let binary = `${transactions.download_data.file_content}`;

      let a = document.createElement("a");
      a.href = binary;
      a.download = transactions.download_data.file_name;
      a.click();
    }
  }, [transactions.download_data]);

  const onEndDateChange = (string) => {
    setEndDate(string);
  };

  const onStartDateChange = (string) => {
    setStartDate(string);
  };

  const onEnquiryChange = (items) => {
    let value = Array.from(
      items.target.selectedOptions,
      (option) => option.value
    );

    setEnquiryItem(value);
  };

  const populateEnquiryDropdown = (data) => {
    if (data.length > 0) {
      return data.map((data) => {
        return (
          <option key={data.id} value={data.id}>
            {"" != data.customer.fullname
              ? `${data.customer.fullname}`
              : `${data.customer.company_name}`}
            , {data.title}
          </option>
        );
      });
    }
  };

  const onSearchFormSubmit = (e) => {
    e.preventDefault();
    let params = {
      enquiry: enquiryItem,
      start_date: startDate,
      end_date: endDate,
    };

    dispatch(getAllTransactions(0, "advanced", params));
  };

  const renderTransactionTableView = (data, id) => {
    const columns = [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "75px",
        cell: (record) => {
          return `#${record.id}`;
        },
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        width: "200px",
      },
      {
        name: "Enquiry",
        selector: (row) => row.enquiry_title,
        sortable: true,
        width: "200px",
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        wrap: true,
      },
      {
        name: "Debit",
        selector: (row) => row.debit,
        sortable: true,
        width: "100px",
      },
      {
        name: "Credit",
        selector: (row) => row.credit,
        sortable: true,
        width: "100px",
      },
      {
        key: "action",
        text: "Proof",
        className: "action",
        width: "130px",
        align: "left",
        sortable: false,
        cell: (record) => {
          return "" !== record.attachment ? (
            <ButtonGroup className="mb-2" size="sm">
              <Button
                title="Attachments"
                variant="secondary"
                onClick={(e) =>
                  onAttachButtonClick(e, record.id, record.attachment)
                }
              >
                <Paperclip size={10}>{record.attachment}</Paperclip>
              </Button>
            </ButtonGroup>
          ) : (
            ""
          );
        },
      },
    ];

    let tableData = [];

    for (let trans of data) {
      let transactionDate = format(
        new Date(trans.transaction_date),
        "dd-LLL-yyyy hh:ii a"
      );

      tableData.push({
        id: trans.id,
        description: trans.description,
        date: transactionDate,
        user: trans.user.name,
        debit: trans.type === "debit" ? trans.amount : "",
        credit: trans.type === "credit" ? trans.amount : "",
        attachment: trans.attachment,
        status: trans.status,
        enquiry_title:
          null !== trans.enquiry ? trans.enquiry.title : "General Expense",
      });
    }
    return (
      <AdvancedDataTable
        title="Advanced Transaction Report"
        columns={columns}
        data={tableData}
        key={tableData.length}
      />
    );
  };

  const onAttachButtonClick = (e, id, attachmentName) => {
    e.preventDefault();
    if ("" !== attachmentName) {
      dispatch(downloadTransactionProof(id));
    }
  };

  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Income Expense Report</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2"></div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <form
                className="needs-validation"
                noValidate=""
                onSubmit={onSearchFormSubmit}
              >
                <div className="row g-3">
                  <div className="col-md-4">
                    <label htmlFor="enquiry" className="form-label">
                      Enquiry
                    </label>
                    <select
                      className="form-select"
                      id="enquiry"
                      value={enquiryItem}
                      onChange={(e) => onEnquiryChange(e)}
                      multiple
                    >
                      <option value="">Choose...</option>
                      {populateEnquiryDropdown(enquiryList)}
                    </select>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="Start Date" className="form-label">
                      Date From
                    </label>
                    <Input
                      type="date"
                      placeholder="Start Date"
                      value={startDate}
                      onChange={(e) => onStartDateChange(e.target.value)}
                      className="form-control"
                    />
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="Start Date" className="form-label">
                      Date To
                    </label>
                    <Input
                      type="date"
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => onEndDateChange(e.target.value)}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12 text-right">
                    <Button
                      variant="primary"
                      className="btn btn-sm float-end pull-right"
                      type="submit"
                    >
                      Generate Report
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="row g-5">
        <div className="col-md-12 col-lg-12">
          {!transactions.loading && transactions.transactions.length > 0
            ? renderTransactionTableView(transactions.transactions, id)
            : ""}
        </div>
      </div>
    </Layout>
  );
};
