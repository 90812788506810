import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCustomerDetails, updateCustomer } from "../../actions";
import { Layout } from "../../components/Layout";
import { Input } from "../../components/UI/Input";

/**
 * @author
 * @function Customers
 **/

export const EditCustomer = (props) => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers);

  const navigate = useNavigate();

  const [fullname, setFullname] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [active, setActive] = useState("");

  const { id } = useParams();

  const onFormSubmit = (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append("fullname", fullname);
    form.append("company_name", fullname);
    form.append("address", address);
    form.append("zip", zip);
    form.append("email", email);
    form.append("mobile", mobile);
    form.append("customer_type", "b2b");
    form.append("status", active);
    form.append("id", id);

    dispatch(updateCustomer(form));

    if (!customers.error) {
      navigate("/customers");
    }
  };

  useEffect(() => {
    dispatch(getCustomerDetails(id));
  }, []);

  useEffect(() => {
    if (customers.customer) {
      const customer = customers.customer;

      setFullname(customer.fullname);
      setCompanyName(customer.company_name);
      setAddress(customer.address);
      setZip(customer.zip);
      setEmail(customer.email);
      setMobile(customer.mobile);
      setActive("1" === customer.status ? true : false);
    }
  }, [customers.customer]);
  return (
    <Layout header sidebar>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Customers</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Link
              to={"/customers"}
              className="btn btn-sm btn-outline-secondary"
            >
              List All
            </Link>
          </div>
        </div>
      </div>

      <Form className="row g-3" onSubmit={onFormSubmit}>
        <div className="col-12">
          <Form.Label className="form-label" htmlFor="fullname">
            Full name
          </Form.Label>
          <Input
            id="name"
            type="text"
            placeholder="Full name"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="col-12">
          <Form.Label className="form-label" htmlFor="company_name">
            Company Name
          </Form.Label>
          <Input
            id="name"
            type="text"
            placeholder="Company name"
            value={company_name}
            onChange={(e) => setCompanyName(e.target.value)}
            className="form-control"
            required
          />
        </div>
        <div className="col-12">
          <Form.Label className="form-label" htmlFor="address">
            Address
          </Form.Label>
          <Input
            id="address"
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <Form.Label className="form-label" htmlFor="zip">
            Zip
          </Form.Label>
          <Input
            id="zip"
            type="text"
            placeholder="Zip"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-md-6">
          <Form.Label className="form-label" htmlFor="mobile">
            Mobile
          </Form.Label>
          <Input
            id="mobile-number"
            type="text"
            placeholder="Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="col-12">
          <Form.Label className="form-label" htmlFor="email">
            Email
          </Form.Label>
          <Input
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="col-12">
          <div className="form-check">
            <input
              className="form-check-input"
              value={active}
              type="checkbox"
              id="active"
              defaultChecked={active}
              checked={active}
              onChange={() => setActive(!active)}
            />
            <Form.Label className="form-check-label" htmlFor="active">
              Active
            </Form.Label>
          </div>
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </Form>
    </Layout>
  );
};
