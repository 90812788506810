import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { enquiryConstant } from "./constants";

export const getAllActivityLog = (enquiry_id) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.GET_ALL_ACTIVITY_LOG_REQUEST,
    });

    const res = await axios.get("enquiry/log/" + enquiry_id);
    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.GET_ALL_ACTIVITY_LOG_SUCCESS,
        payload: {
          activityLog: res.data.data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_ALL_ACTIVITY_LOG_FAILURE,
        payload: {
          error: res.data.message,
        },
      });
    }
  };
};

export const addEnquiryNote = (data) => {
  return async (dispatch) => {
    dispatch({ type: enquiryConstant.ADD_NEW_ENQUIRY_NOTE_REQUEST });

    const res = await axios.post("/enquiry/note/" + data.id, data);

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_NOTE_SUCCESS,
        payload: {
          success: res.data.message,
          error: "",
        },
      });

      dispatch(getAllActivityLog(data.id));

      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_NOTE_FAILURE,
        payload: {
          error: res.data.message,
          success: "",
        },
      });

      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};
