import { enquiryConstant } from "../actions/constants";

const initState = {
  loading: true,
  error: null,
  followup: [],
  message: null,
};

const followupReducer = (state = initState, action) => {
  switch (action.type) {
    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        followup: action.payload.followup,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_FOLLOWUP_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_FOLLOWUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.ADD_NEW_FOLLOWUP_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default followupReducer;
