import { enquiryConstant } from "../actions/constants";

const initState = {
  enquiries: [],
  loading: true,
  error: false,
  enquiry: null,
  message: null,
};

const enquiryReducer = (state = initState, action) => {
  switch (action.type) {
    case enquiryConstant.GET_ALL_ENQUIRY_SUCCESS:
      state = {
        ...state,
        enquiries: action.payload.enquiries,
        loading: false,
      };
      break;
    case enquiryConstant.GET_ALL_ENQUIRY_REQUEST:
      state = initState;
      break;
    case enquiryConstant.GET_SINGLE_ENQUIRY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case enquiryConstant.GET_SINGLE_ENQUIRY_SUCCESS:
      state = {
        ...state,
        enquiry: action.payload.enquiry,
        loading: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload,
      };
      break;
    case enquiryConstant.DELETE_ENQUIRY_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.DELETE_ENQUIRY_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case enquiryConstant.DELETE_ENQUIRY_FAILUE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
      };
      break;

    case enquiryConstant.UPDATE_ENQUIRY_STATUS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case enquiryConstant.UPDATE_ENQUIRY_STATUS_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload,
      };
      break;
    case enquiryConstant.UPDATE_ENQUIRY_STATUS_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
      };
      break;

    case enquiryConstant.GET_ALL_ACTIVITY_LOG_SUCCESS:
      state = {
        ...state,
        activityLog: action.payload.activityLog,
        loading: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
      };
      break;

    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
      };
      break;
    case enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_FAILURE:
      state = {
        ...state,
        error: true,
        loading: false,
      };
      break;

    default:
      return state;
  }

  return state;
};

export default enquiryReducer;
