import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { Link, useParams } from "react-router-dom";
import {
  enquiryStatusUpdate,
  getAllSalesManagers,
  getSingleEnquiry,
  setFollowupItemStatus,
} from "../../actions";
import { Layout } from "../../components/Layout";
import { Input } from "../../components/UI/Input";
import AddNote from "./AddNote";
import { Followup } from "./Followup";
import { format } from "date-fns";
import { Rings } from "react-loader-spinner";
import { AddFollowup } from "./Followup/add";
import { TransactionNotes } from "./TransactionNotes";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

/**
 * @author
 * @function enquiry.enquiry
 **/

export const EnquiryDetails = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const enquiry = useSelector((state) => state.enquiry);
  const { followup } = useSelector((state) => state.followup);
  const activity = useSelector((state) => state.acitivity);
  const { sales_managers } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);

  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [salesManager, setSalesManager] = useState("");
  const [comments, setComments] = useState("");
  const [email, setEmail] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [tripStartDate, setTripStartDate] = useState("");
  const [tripEndDate, setTripEndDate] = useState("");

  const [addFollowupWindowShow, setAddFollowupWindowShow] = useState(false);
  const [addNoteWindowShow, setAddNoteWindowShow] = useState(false);

  useEffect(() => {
    dispatch(getSingleEnquiry(id));
    dispatch(getAllSalesManagers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleAddFollowupWindow = (toggle) => {
    if (addFollowupWindowShow) {
      setAddFollowupWindowShow(false);
    } else {
      setAddFollowupWindowShow(true);
    }
  };

  const toggleAddNoteWindow = (toggle) => {
    if (addNoteWindowShow) {
      setAddNoteWindowShow(false);
    } else {
      setAddNoteWindowShow(true);
    }
  };

  const renderOnChangeTextBox = (status) => {
    if ("2" === status || "3" === status) {
      return (
        <div className="row mt-2">
          <div className="col-md-12">
            <Input
              type="email"
              placeholder="Email"
              value={email ?? enquiry.enquiry.status_item.mandatory_value}
              className="form-control"
              onChange={(e) => onEmailChange(e.target.value)}
              required
            />
          </div>
        </div>
      );
    } else if ("4" === status || "7" === status) {
      return (
        <div className="row mt-2">
          <div className="col-md-12">
            <textarea
              rows={3}
              className="form-control"
              placeholder="Comments"
              onChange={(e) => onCommentChange(e.target.value)}
              required
            >
              {comments ?? enquiry.enquiry.status_item.mandatory_value}
            </textarea>
          </div>
        </div>
      );
    } else if ("5" === status) {
      return (
        <div className="row mt-2">
          <div className="col-md-12">
            <Input
              type="date"
              placeholder="Due Date"
              value={dueDate || enquiry.enquiry.status_item.mandatory_value}
              onChange={(e) => onDueDateChange(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
      );
    } else if ("6" === status) {
      return (
        <div className="row mt-2">
          <div className="col-md-5">
            <label htmlFor="trip-end-date">Start Date</label>
            <Input
              type="date"
              placeholder="Trip Start Date"
              value={tripStartDate || enquiry.enquiry.trip_start_date || ""}
              onChange={(e) => onTripStartDateChange(e.target.value)}
              className="form-control"
              required
            />
          </div>
          <div className="col-md-5">
            <label htmlFor="trip-end-date">End Date</label>
            <Input
              type="date"
              placeholder="Tripe End Date"
              value={tripEndDate || enquiry.enquiry.trip_end_date || ""}
              onChange={(e) => onTripEndDateChange(e.target.value)}
              className="form-control"
              required
            />
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onCommentChange = (string) => {
    setEmail("");
    setTripStartDate("");
    setTripEndDate("");
    setComments(string);
  };

  const onEmailChange = (string) => {
    setEmail(string);
    setDueDate("");
    setComments("");
    setTripStartDate("");
    setTripEndDate("");
  };

  const onDueDateChange = (string) => {
    setEmail("");
    setDueDate(string);
    setComments("");
    setTripStartDate("");
    setTripEndDate("");
  };
  const onTripStartDateChange = (string) => {
    setEmail("");
    setDueDate("");
    setComments("");
    setTripStartDate(string);
  };

  const onTripEndDateChange = (string) => {
    setEmail("");
    setDueDate("");
    setComments("");
    setTripEndDate(string);
  };

  const updateEnuiryStatus = (e) => {
    e.preventDefault();

    if (1 === enquiryStatus || 4 === enquiryStatus) {
      setEmail("");
      setDueDate("");
      setComments("");
      setTripStartDate("");
      setTripEndDate("");
    }

    if ("6" === enquiryStatus) {
      if (
        null === tripStartDate ||
        null === tripEndDate ||
        "" === tripStartDate ||
        "" === tripEndDate
      ) {
        toast.error("Trip Start and End Date are mandatory to Confirm", {
          position: "top-right",
          autoClose: 5000,
        });
        return false;
      }
    }
    let mandatory_value = "";
    let mandatory_field = "";

    if ("" !== email) {
      mandatory_field = "email";
      mandatory_value = email;
    } else if ("" !== dueDate) {
      mandatory_field = "duedate";
      mandatory_value = dueDate;
    } else if ("" !== tripStartDate || "" !== tripEndDate) {
      mandatory_field = "tripStartDate,tripEndDate";
      mandatory_value = tripStartDate + "," + tripEndDate;
    } else if ("" !== comments) {
      mandatory_field = "comments";
      mandatory_value = comments;
    } else {
      mandatory_field = "";
      mandatory_value = "";
    }

    const data = {
      id: id,
      status: enquiryStatus || enquiry.enquiry.enquiy_status,
      mandatory_value: mandatory_value,
      mandatory_field: mandatory_field,
      user_id: salesManager || enquiry.enquiry.user_id,
    };
    dispatch(enquiryStatusUpdate(data));
  };
  const renderActivityLog = (activityLogs) => {
    const columns = [
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        width: "180px",
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        wrap: true,
      },
      {
        name: "User",
        selector: (row) => row.user,
        sortable: true,
        width: "180px",
      },
    ];

    let logData = [];

    for (let log of activityLogs) {
      let logDate = format(new Date(log.created_at), "dd-LLL-yyyy hh:ii a");

      logData.push({
        id: log.id,
        description: log.description,
        date: logDate,
        user: log.user.name,
      });
    }

    return <DataTable columns={columns} data={logData} />;
  };

  const markFollowupItemCompleted = (e) => {
    let status = 0;
    if (e.target.checked) {
      status = 1;
    }

    const data = {
      id: e.target.value,
      status: status,
      enquiry_id: id,
    };

    dispatch(setFollowupItemStatus(data));
  };

  const renderFollowupItems = (lists) => {
    if ("" !== lists) {
      var followupList = [];
      lists.forEach((data) => {
        if ("" !== data) {
          let followup_id = data.id;
          followupList.push(
            <Followup
              key={followup_id}
              id={followup_id}
              type={data.followup_type}
              title={data.comment}
              due_date={data.date_time}
              completed={data.completed}
              onChange={(e) => markFollowupItemCompleted(e)}
              value={followup_id}
            ></Followup>
          );
        }
      });
      return followupList;
    }

    return <></>;
  };

  return (
    <>
      <Layout sidebar header>
        {enquiry.loading ? (
          <Rings height="100" width="100" color="grey" ariaLabel="loading" />
        ) : (
          <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              <h1 className="h2">{enquiry.enquiry.title}</h1>
              <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="row g-5">
              <div className="col-md-7 col-lg-8">
                <div className="row">
                  <div className="col-md-12 text-break">
                    {enquiry.enquiry.description
                      .split("\n")
                      .map((item, idx) => {
                        return (
                          <span key={idx}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                  </div>
                </div>
                <hr className="my-4"></hr>
                <div className="row mb-1">
                  <Form className="needs-validation">
                    {"su" === user.data.user_type ? (
                      <div className="mb-3">
                        <label htmlFor="email">Sales Manager</label>{" "}
                        <select
                          className="form-select"
                          id="state"
                          required
                          value={salesManager || enquiry.enquiry.user_id}
                          onChange={(e) => setSalesManager(e.target.value)}
                        >
                          <option>Choose</option>
                          {Object.keys(sales_managers).length !== 0
                            ? sales_managers.map((user) => (
                                <option key={user.id} value={user.id}>
                                  {user.name}
                                </option>
                              ))
                            : ""}
                        </select>
                        <div className="invalid-feedback">
                          Please enter a valid email address for shipping
                          updates.
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mb-3">
                      <label htmlFor="email">Status</label>{" "}
                      <select
                        className="form-select"
                        id="state"
                        required
                        value={enquiryStatus || enquiry.enquiry.enquiy_status}
                        onChange={(e) => setEnquiryStatus(e.target.value)}
                      >
                        <option value="">Choose...</option>
                        <option value={1}>Unattended</option>
                        <option value={2}>Quote Received</option>
                        <option value={3}>Quote Submitted</option>
                        <option value={4}>Customizing</option>
                        <option value={5}>Awaiting Order</option>
                        <option value={6}>Work Confirmed</option>
                        <option value={7}>Cancelled</option>
                      </select>
                    </div>
                    {renderOnChangeTextBox(
                      enquiryStatus || enquiry.enquiry.enquiy_status
                    )}
                    <div className="col-md-12 text-right">
                      <Button
                        variant="primary"
                        className="btn btn-sm float-end pull-right"
                        type="button"
                        onClick={(e) => updateEnuiryStatus(e)}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                </div>
                <hr className="my-4"></hr>
                <div className="row mb-1">
                  <div className="col-md-12">
                    <h5 className="mb-3">Activity Log</h5>
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary"
                      onClick={() => toggleAddNoteWindow()}
                      className="float-end"
                    >
                      Add Notes
                    </Button>
                  </div>
                </div>
                <div className="row mb-1">
                  {null != activity.activities ? (
                    renderActivityLog(activity.activities)
                  ) : (
                    <></>
                  )}
                </div>
                <hr className="my-4"></hr>
                <div className="row mb-1">
                  <div className="col-md-12">
                    <h5 className="mb-3">Finance/Transactions</h5>
                  </div>
                  <div className="col-md-12">
                    <TransactionNotes></TransactionNotes>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-lg-4 order-md-last">
                <h6 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-default">Customer Information</span>
                  <Link
                    className="btn btn-sm btn-primary float-end"
                    size="sm"
                    variant="primary"
                    to={"/customers/edit/" + enquiry.enquiry.customer.id}
                  >
                    Edit
                  </Link>
                </h6>
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                      <h6 className="my-0">
                        {enquiry.enquiry.customer.fullname}
                      </h6>
                      <small className="text-muted">
                        {enquiry.enquiry.customer.company}
                      </small>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                      <small className="text-muted">Mobile Number</small>
                      <h6 className="my-0">
                        {enquiry.enquiry.customer.mobile}
                      </h6>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                      <small className="text-muted">Email</small>
                      <h6 className="my-0">{enquiry.enquiry.customer.email}</h6>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>
                      <small className="text-muted">Address</small>
                      <h6 className="my-0">{enquiry.enquiry.address}</h6>
                    </div>
                  </li>
                </ul>
                <h6 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-default">References</span>
                </h6>
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between">
                    <div>
                      <small className="text-muted">Quotation Number</small>
                      <h6 className="my-0">{enquiry.enquiry.quote_number}</h6>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>
                      <small className="text-muted">Invoice Number</small>
                      <h6 className="my-0">
                        {enquiry.enquiry.inv_number || "Not Generated"}
                      </h6>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <div>
                      <small className="text-muted">PO Number</small>
                      <h6 className="my-0">
                        {enquiry.enquiry.po_number || "Not Generated"}
                      </h6>
                    </div>
                  </li>
                </ul>
                <h6 className="d-flex justify-content-between align-items-center mb-3">
                  <span className="text-default">Followup</span>
                  <Button
                    size="sm"
                    variant="warning"
                    onClick={() => toggleAddFollowupWindow()}
                  >
                    Set Followup Call
                  </Button>
                </h6>
                <div className="list-group w-auto">
                  {null != followup ? renderFollowupItems(followup) : <></>}
                </div>
              </div>
            </div>
          </>
        )}

        <AddFollowup
          show={addFollowupWindowShow}
          onHide={() => setAddFollowupWindowShow(false)}
        ></AddFollowup>
        <AddNote
          show={addNoteWindowShow}
          onHide={() => setAddNoteWindowShow(false)}
        ></AddNote>
      </Layout>
    </>
  );
};
