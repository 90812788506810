import { enquiryConstant } from "../actions/constants";

const initState = {
  loading: true,
  error: false,
  activities: [],
  message: null,
};

const activityReducer = (state = initState, action) => {
  switch (action.type) {
    case enquiryConstant.GET_ALL_ACTIVITY_LOG_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case enquiryConstant.GET_ALL_ACTIVITY_LOG_SUCCESS:
      state = {
        ...state,
        activities: action.payload.activityLog,
        loading: false,
        error: false,
      };
      break;
    case enquiryConstant.GET_ALL_ACTIVITY_LOG_FAILURE:
      state = {
        ...state,
        activities: [],
        loading: false,
        error: true,
        message: action.payload.error,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: false,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: false,
        message: action.payload.success,
      };
      break;
    case enquiryConstant.ADD_NEW_ENQUIRY_NOTE_FAILURE:
      state = {
        ...state,
        initState,
        error: true,
        loading: false,
        message: action.payload.error,
      };
      break;

    default:
      return state;
  }
  return state;
};

export default activityReducer;
