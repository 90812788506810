import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import DataTable from "react-data-table-component";

import PropTypes from "prop-types";

/**
 * @author
 * @class EnquiryDataTable
 **/

class EnquiryDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enquiryData: [],
      columnData: [],
      currentItem: "",
    };
  }

  prepareStatusText = (value) => {
    let statusCollection = [
      "Unattended",
      "Quote Received",
      "Quote Submitted",
      "Customizing",
      "Awaiting Order",
      "Work Confirmed",
      "Cancelled",
    ];

    return statusCollection[value - 1];
  };

  prepareColumnData = (record) => {
    const dataType = this.props.type;
    let dateHeader = "";
    let dateValue = "";

    if ("upcoming" === dataType || "ongoing" === dataType) {
      dateHeader = "Start & End Date";
    } else if ("completed" === dataType) {
      dateHeader = "End Date";
    } else {
      dateHeader = "Created Date";
    }
    const columns = [
      {
        name: dateHeader,
        selector: (row) => row.created_date,
        sortable: true,
        width: "200",
        cell: (record) => {
          return <span>{this.getTripDate(record)}</span>;
        },
      },
      {
        name: "Client",
        selector: (row) => row.customer.fullname ?? row.customer.company,
        sortable: true,
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        width: "200",
        cell: (record) => {
          return <span>{record.title}</span>;
        },
      },
      {
        name: "Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (record) => {
          return this.prepareStatusText(record.enquiy_status);
        },
      },

      {
        name: "Quote Received",
        selector: (row) => row.quote_received,
        sortable: true,
        width: "90",
        conditionalCellStyles: [
          {
            when: (row) => "Yes" === row.quote_received,
            style: {
              backgroundColor: "#40826d",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => "No" === row.quote_received,
            style: {
              backgroundColor: "#b92e34",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
        ],
      },
      {
        name: "Quote Submitted",
        selector: (row) => row.quote_submitted,
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => "Yes" === row.quote_submitted,
            style: {
              backgroundColor: "#40826d",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => "No" === row.quote_submitted,
            style: {
              backgroundColor: "#b92e34",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
        ],
      },
      {
        name: "Customizing",
        selector: (row) => row.customizing,
        sortable: true,
        width: "75",
        conditionalCellStyles: [
          {
            when: (row) => "No" === row.customizing,
            style: {
              backgroundColor: "white",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
          {
            when: (row) => "Yes" === row.customizing,
            style: {
              backgroundColor: "#40826d",
              color: "white",
              "&:hover": {
                cursor: "pointer",
              },
            },
          },
        ],
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return "su" === this.props.user.data.user_type &&
            "-1" !== record.status ? (
            <>
              <a
                href={"/enquiry/edit/" + record.id}
                className="btn btn-primary btn-sm"
                role="button"
              >
                <i className="bi bi-pencil-fill"></i>
              </a>
              &nbsp;
              <Button
                type="submit"
                variant="danger"
                size="sm"
                onClick={() => this.props.onDeleteClick(record)}
              >
                <i className="bi bi-trash"></i>
              </Button>
              &nbsp;
              <Button
                type="submit"
                variant="info"
                size="sm"
                onClick={() => this.props.onInfoClick(record)}
              >
                <i className="bi bi-person"></i>
              </Button>
            </>
          ) : (
            <a
              href={"/enquiry/edit/" + record.id}
              className="btn btn-primary btn-sm"
              role="button"
            >
              Edit
            </a>
          );
        },
      },
    ];

    this.setState({
      columnData: columns,
    });
  };

  prepareTableData = (data) => {
    let enquiryItems = [];

    if ("" !== data && 0 < data.length) {
      for (let enq of data) {
        let createdDate = format(
          new Date(enq.created_at),
          "dd-LLL-yyyy hh:ii a"
        );

        let enqQuoteSubmitted = "No";
        let enqQuoteReceived = "No";
        let enqCustomizing = "No";

        if ("" !== enq.enquiry_status || enq.enquiry_status.length > 0) {
          enq.enquiry_status.forEach((eqst) => {
            if (2 === eqst.enquiry_status && "1" === eqst.status) {
              enqQuoteReceived = "Yes";
            }
            if (3 === eqst.enquiry_status && "1" === eqst.status) {
              enqQuoteSubmitted = "Yes";
            }
            if (4 === eqst.enquiry_status && "1" === eqst.status) {
              enqCustomizing = "Yes";
            }
          });
        }

        enquiryItems.push({
          id: enq.id,
          created_date: createdDate,
          company: enq.customer.company_name,
          quote_received: enqQuoteReceived,
          quote_submitted: enqQuoteSubmitted,
          customizing: enqCustomizing,
          title: enq.title,
          enquiy_status: enq.enquiy_status,
          customer: enq.customer,
          trip_start_date: enq.trip_start_date,
          trip_end_date: enq.trip_end_date,
        });
      }

      this.setState({
        enquiryData: enquiryItems,
      });
    }
  };

  getTripDate(data) {
    const tripEndDate = null === data.trip_end_date ? "NA" : data.trip_end_date;
    const dataType = this.props.type;
    let dateValue = "";
    if ("upcoming" === dataType || "ongoing" === dataType) {
      dateValue = data.trip_start_date + " - " + tripEndDate;
    } else if ("completed" === dataType) {
      dateValue = tripEndDate;
    } else {
      dateValue = data.created_date;
    }

    return dateValue;
  }

  componentDidMount() {
    this.prepareColumnData();
    this.prepareTableData(this.props.enquiries.enquiries);
  }

  render() {
    return (
      <div>
        <DataTable
          columns={this.state.columnData}
          data={this.state.enquiryData}
          pagination
        />
      </div>
    );
  }
}

export default EnquiryDataTable;
