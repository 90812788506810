import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomers } from "../../../actions";

export const EnquiryFilter = (props) => {
  const { customers } = useSelector((state) => state.customers);
  const [enquiryStatus, setEnquiryStatus] = useState("");
  const [agency, setAgency] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCustomers());
  }, []);

  const populateAgentsDropdown = (data) => {
    return data.map((data) => {
      return (
        <option key={data.id} value={data.id}>
          {"" !== data.fullname
            ? `${data.fullname}, ${data.company_name}`
            : `${data.company_name}`}
        </option>
      );
    });
  };

  const onStatusDropdownChange = (event) => {
    setEnquiryStatus(event.target.value);
  };

  const onAgencyDropDownChange = (event) => {
    setAgency(event.target.value);
  };

  const onSearchFilterSubmit = (e) => {
    e.preventDefault();
    const params = {
      enquiry_status: enquiryStatus,
      agency: agency,
    };

    props.onFilterSubmit(params);
  };

  return (
    <Form onSubmit={(e) => onSearchFilterSubmit(e)}>
      <div className="row">
        <div className="col-md-5 mb-3">
          <label htmlFor="country">Status</label>
          <select
            className="form-select d-block w-100"
            id="status"
            value={enquiryStatus}
            onChange={onStatusDropdownChange}
          >
            <option value="">Choose...</option>
            <option value={1}>Unattended</option>
            <option value={2}>Quote Received</option>
            <option value={3}>Quote Submitted</option>
            <option value={4}>Customizing</option>
            <option value={5}>Awaiting Order</option>
            <option value={6}>Work Confirmed</option>
            <option value={7}>Cancelled</option>
          </select>
        </div>
        <div className="col-md-5 mb-3">
          <label htmlFor="state">Agency</label>
          <select
            className="form-select d-block w-100"
            id="agency"
            value={agency}
            onChange={onAgencyDropDownChange}
          >
            <option value="">Choose...</option>
            {populateAgentsDropdown(customers)}
          </select>
        </div>
        <div className="col-md-2 mb-3">
          <Button
            variant="primary"
            className="btn btn-md float-end pull-right"
            type="submit"
            style={{ marginTop: "22px" }}
          >
            Search
          </Button>
        </div>
      </div>
    </Form>
  );
};
