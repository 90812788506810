import axios from "../helpers/axios";
import { authConstants } from "./constants";

export const login = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    try {
      const res = await axios.post("/login", {
        client_secret: process.env.SERVICE_CLIENT_SECRET,
        client_id: process.env.SERVICE__PERSONAL_ACCESS_CLIENT_ID,
        grant_type: "password",
        ...user,
      });

      if (res.status === 200) {
        const { access_token } = res.data.data;

        const userData = await axios.get("/profile", {
          headers: {
            Authorization: access_token ? `Bearer ${access_token}` : "",
          },
        });

        const user = userData.data;

        localStorage.setItem("token", access_token);
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            access_token,
            user,
          },
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { message: res.data.message, error: true },
        });
      }
    } catch (error) {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { message: "", error: true },
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const access_token = localStorage.getItem("token");

    if (access_token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          access_token,
          user,
        },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    const res = await axios.post("/logout");

    if (res.status === 200) {
      localStorage.clear();
      dispatch({
        type: authConstants.LOGOUT_SUCCESS,
      });
    } else {
      dispatch({
        type: authConstants.LOGOUT_FAILURE,
        payload: {
          error: res.data.error,
        },
      });
    }
  };
};
