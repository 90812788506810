import { toast } from "react-toastify";
import axios from "../helpers/axios";
import { getAllActivityLog } from "./activity.action";

import { enquiryConstant } from "./constants";

export const addEnquiryFollowup = (data) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.ADD_NEW_ENQUIRY_FOLLOWUP_REQUEST,
    });

    const res = await axios.post("/enquiry/followup/" + data.id, data);

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_FOLLOWUP_SUCCESS,
        payload: {
          success: true,
          error: false,
          message: res.data.message,
        },
      });

      dispatch(getAllActivityLog(data.id));
      dispatch(getAllEnquiryFollowup(data.id));

      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.ADD_NEW_ENQUIRY_FOLLOWUP_FAILURE,
        error: res.data.message,
        success: "",
      });
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};

export const getAllEnquiryFollowup = (id) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_REQUEST,
    });

    const res = await axios.get("/enquiry/followup/" + id);

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_SUCCESS,
        payload: {
          error: "",
          success: res.data.message,
          followup: res.data.data,
        },
      });
    } else {
      dispatch({
        type: enquiryConstant.GET_ALL_ENQUIRY_FOLLOWUP_FAILURE,
        error: res.data.message,
        success: "",
      });
    }
  };
};

export const setFollowupItemStatus = (data) => {
  return async (dispatch) => {
    dispatch({
      type: enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_REQUEST,
    });

    const res = await axios.post(
      `/enquiry/followup_completed/${data.id}`,
      data
    );

    if (res.status === 200) {
      dispatch({
        type: enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_SUCCESS,
        payload: {
          error: "",
          success: res.data.message,
        },
      });

      dispatch(getAllActivityLog(data.enquiry_id));
      dispatch(getAllEnquiryFollowup(data.enquiry_id));

      toast.success(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      dispatch({
        type: enquiryConstant.SET_ENQUIRY_FOLLOWUP_STATUS_UPDATE_FAILURE,
        payload: {
          error: res.data.message,
          success: "",
        },
      });
      toast.error(res.data.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };
};
