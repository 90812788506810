import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";

/**
 * @author
 * @function AdvancedDataTable
 **/

const Export = ({ onExport }) => (
  <Button onClick={(e) => onExport(e.target.value)}>Export</Button>
);

export const AdvancedDataTable = (props) => {
  const [data, setData] = useState(props.data);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const convertArrayOfObjectsToCSV = (array) => {
    let result;

    const skipItems = ["attachment", "status"];
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    let keys = Object.keys(data[0]);
    keys = keys.filter((x) => !skipItems.includes(x));

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        let itemValue = "";
        if (!skipItems.includes(key)) {
          if (ctr > 0) result += columnDelimiter;
          if (item[key].toString().includes(",")) {
            itemValue = '"' + item[key] + '"';
          } else {
            itemValue = item[key];
          }
          if ("enquiry_title" === key && "" === itemValue) {
            itemValue = "General Expense";
          }
          result += itemValue;
          // eslint-disable-next-line no-plusplus
          ctr++;
        }
      });
      result += lineDelimiter;
    });

    return result;
  };

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const downloadCSV = (array) => {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };

  const actionsMemo = useMemo(
    () => <Export onExport={() => downloadCSV(data)} />,
    [data]
  );

  return (
    <DataTable
      title={props.title}
      columns={props.columns}
      data={data}
      actions={actionsMemo}
    />
  );
};

export default AdvancedDataTable;
