import React from "react";
import { Activity, CheckCircle, Plus, RotateCw, Trash } from "react-feather";
import { Link } from "react-router-dom";

/**
 * @author
 * @function EnquiryMenu
 **/

export const EnquiryMenu = (props) => {
  return (
    <div
      key={props.showTrash}
      className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 className="h2">{props.title}</h1>
      <div className="btn-toolbar mb-2 mb-md-0">
        {!props.hideToolbar ? (
          <div className="btn-group me-2">
            <Link
              to={"/enquiry/add"}
              className="btn btn-sm btn-outline-secondary"
            >
              <Plus
                className="feather feather-home align-text-bottom"
                size={8}
              ></Plus>
              &nbsp; Add New
            </Link>
            <Link
              to={"/enquiry/upcoming"}
              className="btn btn-sm btn-outline-secondary"
            >
              <RotateCw className="feather feather-hone align-text-bottom"></RotateCw>
              &nbsp; Upcoming
            </Link>
            <Link
              to={"/enquiry/ongoing"}
              className="btn btn-sm btn-outline-secondary"
            >
              <Activity
                className="feather feather-home align-text-bottom"
                size={8}
              ></Activity>
              &nbsp; Ongoing
            </Link>
            <Link
              to={"/enquiry/completed"}
              className="btn btn-sm btn-outline-secondary"
            >
              <CheckCircle className="feather feather-hone align-text-bottom"></CheckCircle>
              &nbsp; Completed
            </Link>
            <Link
              to={"/enquiry/trash"}
              className="btn btn-sm btn-outline-secondary"
            >
              <Trash
                className="feather feather-home align-text-bottom"
                size={8}
              ></Trash>
              &nbsp; View Trash
            </Link>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
