import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, ButtonGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Paperclip, RotateCw, Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addTransactionNote,
  downloadTransactionProof,
  getAllTransactions,
  uploadTransactionProof,
  voidTransactionNote,
} from "../../../actions";
import { Input } from "../../../components/UI/Input";
import { Summary } from "./summary";

/**
 * @author
 * @function TransactionNotes
 **/

export const TransactionNotes = (props) => {
  const dispatch = useDispatch();
  const transactions = useSelector((state) => state.transactions);
  const [show, setShow] = useState(false);
  const [attachFormShow, setAttachFormShow] = useState(false);
  const [noteType, setNoteType] = useState();
  const [transactionDate, setTransactionDate] = useState("");
  const [currency, setCurrency] = useState("1");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  let { id } = useParams("");
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if ("general" === props.type) {
      id = 0;
    } else if ("all" === props.type) {
      id = "-1";
    }
    dispatch(getAllTransactions(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (null != transactions.download_data) {
      let binary = `${transactions.download_data.file_content}`;

      let a = document.createElement("a");
      a.href = binary;
      a.download = transactions.download_data.file_name;
      a.click();
    }
  }, [transactions.download_data]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAttachClose = () => setAttachFormShow(false);
  const handleAttachShow = () => setAttachFormShow(true);

  const renderTransactionTableView = (data) => {
    const conditionalRowStyles = [
      {
        when: (row) => row.status == "0",
        style: {
          backgroundColor: "#E5E4E2",
          color: "#666666",
          "&:hover": {
            cursor: "pointer",
          },
        },
      },
    ];

    const columns = [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
        width: "100px",
        cell: (record) => {
          return `#${record.id}`;
        },
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        width: "200px",
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
        wrap: true,
        width: 200,
      },
      {
        name: "Debit",
        selector: (row) => row.debit,
        sortable: true,
        width: "100px",
      },
      {
        name: "Credit",
        selector: (row) => row.credit,
        sortable: true,
        width: "100px",
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: "130px",
        align: "left",
        sortable: false,
        cell: (record) => {
          return "0" !== record.status ? (
            <ButtonGroup className="mb-2" size="sm">
              <Button
                title="Attachments"
                variant="secondary"
                onClick={(e) =>
                  onAttachButtonClick(e, record.id, record.attachment)
                }
              >
                <Paperclip size={10}></Paperclip>
              </Button>
              <Button
                title="Void"
                variant="warning"
                onClick={(e) => onVoidClick(e, record.id)}
              >
                <RotateCw size={10}></RotateCw>
              </Button>
              <Button
                className={`${"su" !== user.data.user_type ? "d-none" : ""}`}
                title="Delete"
                variant="danger"
                onClick={(e) => onDeleteClick(e, record.id)}
              >
                <Trash size={10}></Trash>
              </Button>
            </ButtonGroup>
          ) : (
            ""
          );
        },
      },
    ];

    let tableData = [];

    for (let trans of data) {
      let transactionDate = format(
        new Date(trans.transaction_date),
        "dd-LLL-yyyy hh:ii a"
      );

      tableData.push({
        id: trans.id,
        description: trans.description,
        date: transactionDate,
        user: trans.user.name,
        debit: trans.type === "debit" ? trans.amount : "",
        credit: trans.type === "credit" ? trans.amount : "",
        attachment: trans.attachment,
        status: trans.status,
      });
    }

    return (
      <DataTable
        columns={columns}
        data={tableData}
        pagination
        conditionalRowStyles={conditionalRowStyles}
      />
    );
  };

  const onAttachButtonClick = (e, id, attachmentName) => {
    e.preventDefault();
    if ("" === attachmentName) {
      setTransactionId(id);
      setAttachFormShow(true);
    } else {
      dispatch(downloadTransactionProof(id));
    }
  };

  const onDeleteClick = (e, id) => {
    e.preventDefault();
  };
  const onVoidClick = (e, transaction_id) => {
    e.preventDefault();
    dispatch(voidTransactionNote(transaction_id, id));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const enquiry_id = typeof id !== "undefined" ? id : 0;
    const formData = new FormData();
    formData.append("enquiry_id", enquiry_id);
    formData.append("type", noteType);
    formData.append("date", transactionDate);
    formData.append("currency_id", currency);
    formData.append("amount", amount);
    formData.append("description", description);
    formData.append("type", noteType);
    formData.append("attachment", attachment);

    dispatch(addTransactionNote(formData));

    setShow(false);
    setNoteType("");
    setTransactionDate("");
    setAmount("");
    setDescription("");
    setAttachment("");
  };

  const handleAttachSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", transactionId);
    formData.append("attachment", attachment);
    formData.append("enquiry_id", id);
    dispatch(uploadTransactionProof(formData));
    setAttachFormShow(false);
    setAttachment("");
  };

  const onFileSelected = (e) => {
    let reader = new FileReader();
    reader.onload = (f) => {
      setAttachment(f.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <Button
        size="sm"
        variant="info"
        className="float-end mb-1"
        onClick={handleShow}
      >
        Add Transaction
      </Button>
      <Modal show={attachFormShow} onHide={handleAttachShow}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <Input
              id="attachment"
              type="file"
              placeholder="0.00"
              onChange={(e) => onFileSelected(e)}
              className="form-control"
              accept="image/png, image/gif, image/jpeg,a pplication/pdf, application/vnd.ms-excel"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleAttachClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAttachSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <Input
              id="transaction_date"
              type="date"
              placeholder="date"
              value={transactionDate}
              onChange={(e) => setTransactionDate(e.target.value)}
              className="form-control"
            />
            <Form.Label className="form-label" htmlFor="transaction_date">
              Transaction Date
            </Form.Label>
          </div>
          <div className="form-floating mb-3">
            <select
              id="notification-type"
              className="form-select"
              required
              value={noteType}
              onChange={(e) => setNoteType(e.target.value)}
            >
              <option value="">Choose</option>
              <option value="debit">Debit Note</option>
              <option value="credit">Credit Note</option>
            </select>
            <Form.Label className="form-label" htmlFor="notification-type">
              Transaction Type
            </Form.Label>
          </div>
          <div className="form-floating mb-3">
            <select
              id="currency"
              className="form-select"
              required
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              defaultValue={1}
            >
              <option value="">Choose</option>
              <option value={1}>INR</option>
              <option value={2}>QR</option>
              <option value={3}>USD</option>
            </select>
            <Form.Label className="form-label" htmlFor="notification-type">
              Transaction Type
            </Form.Label>
          </div>
          <div className="form-floating mb-3">
            <Input
              id="amount"
              type="text"
              placeholder="0.00"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="form-control"
            />
            <Form.Label className="form-label" htmlFor="amount">
              Amount
            </Form.Label>
          </div>
          <div className="form-floating mb-3">
            <Input
              id="description"
              type="text"
              placeholder="0.00"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
            />
            <Form.Label className="form-label" htmlFor="description">
              Description
            </Form.Label>
          </div>
          <div className="form-floating mb-3">
            <Input
              id="attachment"
              type="file"
              placeholder="0.00"
              onChange={(e) => onFileSelected(e)}
              className="form-control"
              accept="image/png, image/gif, image/jpeg,a pplication/pdf, application/vnd.ms-excel"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {!transactions.loading && transactions.transactions.length > 0
        ? renderTransactionTableView(transactions.transactions)
        : ""}

      {!props.hideSummary ? (
        <div className="row mt-4">
          <div className="col-md-12">
            <h5 className="mb-3">Finance Summary </h5>
            {!transactions.loading && "" !== transactions.summary ? (
              <Summary
                credit={transactions.summary.total_credits || "0.00"}
                debit={transactions.summary.total_debits}
                balance={transactions.summary.balance}
              ></Summary>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
