import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { Form, Button } from "react-bootstrap";
import { Input } from "../../components/UI/Input";
import { login } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import "./signin.css";
import logo from "../../logo.png";
import { toast } from "react-toastify";

/**
 * @author
 * @function Signin
 **/

const Signin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [resMessage, setResMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.authenticate) {
      navigate("/enquiry");
    } else {
      if (true === auth.error) {
        toast.error("Authentication failed.  Please check your credentials.", {
          position: "top-right",
          autoClose: 5000,
        });
      }
    }
  }, [auth]);

  const userLogin = (e) => {
    e.preventDefault();

    const user = {
      email,
      password,
    };
    dispatch(login(user));
  };

  return (
    <Layout>
      <main className="form-signin w-100">
        <Form onSubmit={userLogin}>
          <img className="mb-4" src={logo} alt="TripifyMe" width={315} />
          <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
          {resMessage !== "" ? <div>{resMessage}</div> : <></>}
          <div className="form-floating">
            <Input
              id="email"
              className="form-control"
              type="email"
              placeholder="Enter Email"
              label="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </div>
          <div className="form-floating">
            <Input
              id="password"
              className="form-control"
              type="password"
              placeholder="Enter Password"
              label="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </div>
          <Button variant="light" type="submit">
            Submit
          </Button>
          <p className="mt-5 mb-3 text-muted">&copy; 2017–2022</p>
        </Form>
      </main>
    </Layout>
  );
};
export default Signin;
